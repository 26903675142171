import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import ZeqPointsOfSale from '../../components/ZeqPointsOfSale/ZeqPointsOfSale';

const ZeqPointsOfSalePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Pontos de Venda do Zequinha">
			<ZeqPointsOfSale />
		</Layout>
	);
};

export default ZeqPointsOfSalePage;
