import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from './styles'
import logoFacebook from '../../assets/icons/icon_facebook.svg'
import logoInstagram from '../../assets/icons/icon_instagram.svg'
import logolinkedin from '../../assets/icons/icon_linkedin.svg'
import logoVisa from '../../assets/icons/icon_visa.svg'
import logoMastercard from '../../assets/icons/icon_mastercard.svg'
import logoElo from '../../assets/icons/icon_elo.png'
import logoPix from '../../assets/icons/icon_pix.svg'
import logoBoleto from '../../assets/icons/icon_boleto.svg'
import logoSac from '../../assets/icons/icon_sac.svg'
import GoogleSafe from '../../assets/googlesafe.png'
import segurossl from '../../assets/segurossl.png'
import letsencrypt from '../../assets/icons/lets_encrypt.svg'
import Logopp from '../../assets/logopp.png'
import sambayLogo from '../../assets/logo.svg'
import LogoFaceFooter from '../../assets/icons/logofacefooter.svg'
import LogoInstaFooter from '../../assets/icons/logoinstafooter.svg'
import LogoLinkedinFooter from '../../assets/icons/logolinkedinfooter.svg'
import IconButton from '../../assets/icon_whats.png'
import ReclameAquiPicture from '../../assets/reclame_aqui.png'
import useWindow from '../../hooks/useWindow'
import { MdOutlineLocalShipping } from 'react-icons/md'

const Footer: React.FC = () => {
    const { innerWidth } = useWindow()
    const history = useHistory()

    return (
        <Container>
            {/* CÓDIGO DE RODAPÉ PARA DESKTOP */}
            {innerWidth >= 1023 && (
                <>
                    <div className="footer-layout">
                        <div className="section-footer-logo">
                            <div className="footer-logo">
                                <img
                                    src={sambayLogo}
                                    alt="Logotipo Sambay Rodapé"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="footer-infos">
                        <div className="section-footer-infos">
                            <h2>Atendimento ao cliente</h2>
                            <span
                                className="span-redirect"
                                onClick={() => history.push(`/faq`)}
                            >
                                Central de Ajuda/FAQ
                            </span>
                            <a
                                href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                                className="span-redirect"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Como vender
                            </a>
                            <a
                                className="span-redirect"
                                href="https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Fale conosco
                            </a>
                            <span
                                className="span-redirect"
                                onClick={() => history.push(`/my-buys`)}
                            >
                                Rastreie seu Pedido
                            </span>
                            <span
                                className="span-redirect"
                                onClick={() =>
                                    history.push(`/commission-policy`)
                                }
                            >
                                Taxas e Comissões
                            </span>
                            <span className="span-redirect tw-mr-2">
                                Políticas de:
                            </span>
                            <div>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/warranty-policy`)
                                    }
                                >
                                    Garantia
                                </span>
                                <span className="tw-px-2 tw-text-white">|</span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/exchange-policy`)
                                    }
                                >
                                    Trocas
                                </span>
                                <span className="tw-px-2 tw-text-white">|</span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/return-policy`)
                                    }
                                >
                                    Devoluções
                                </span>

                                <span className="tw-px-2 tw-text-white">|</span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/refund-policy`)
                                    }
                                >
                                    Reembolso
                                </span>
                            </div>
                        </div>

                        <div className="section-footer-infos">
                            <h2>Sobre a Sambay</h2>
                            <span
                                onClick={() => history.push(`/about-us`)}
                                className="span-redirect"
                            >
                                Sobre nós
                            </span>
                            <a
                                href={`${process.env.REACT_APP_STATIC_URL}/policy/termos.pdf`}
                                className="span-redirect"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Termos de uso
                            </a>
                            <span
                                onClick={() => history.push(`/privacy-policy`)}
                                className="span-redirect"
                            >
                                Politica de Privacidade
                            </span>
                            <span
                                onClick={() => history.push(`/social-projects`)}
                                className="span-redirect"
                            >
                                Projetos Sociais
                            </span>
                            <span
                                onClick={() => history.push(`/zeq-points-of-sale`)}
                                className="span-redirect"
                            >
                                Pontos de Venda do Zequinha
                            </span>
                            <a></a>
                        </div>

                        <div className="section-footer-infos">
                            <h2>Pagamento</h2>
                            <img
                                src={logoVisa}
                                alt="logo-visa"
                                className="logo-visa"
                            />
                            <img
                                src={logoMastercard}
                                alt="logo-mastercard"
                                className="logo-mastercard"
                            />
                            <img
                                src={logoElo}
                                alt="logo-elo"
                                className="logo-elo"
                            />
                            <img
                                src={logoPix}
                                alt="logo-pix"
                                className="logo-pix"
                            />
                            <img
                                src={logoBoleto}
                                alt="logo-boleto"
                                className="logo-boleto"
                            />
                            <img />
                            <img />
                        </div>

                        <div className="section-footer-infos">
                            <h2>Siga-nos</h2>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/sambayexpress"
                            >
                                <img
                                    src={logoFacebook}
                                    className="icon-social-media"
                                    alt="icon-facebook"
                                />{' '}
                                Facebook
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/sambayexpress/"
                            >
                                <img
                                    src={logoInstagram}
                                    className="icon-social-media"
                                    alt="icon-instagram"
                                />{' '}
                                Instagram
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/company/sambay-express/"
                            >
                                <img
                                    src={logolinkedin}
                                    className="icon-social-media"
                                    alt="icon-linkedin"
                                />{' '}
                                Linkedin
                            </a>
                            <a></a>
                            <a></a>
                            <a></a>
                        </div>

                        <div className="section-footer-infos">
                            <div className="section-sac">
                                <div className="section-img-sac">
                                    <img src={logoSac} alt="logo-sac" />
                                    <div className="section-phone-number">
                                        <h2>SAC</h2>
                                        <span>9090 98509-4049</span>
                                    </div>
                                </div>
                                <span>
                                    Atendimento das
                                    <br />
                                    <strong>08:30 até 17:00</strong>
                                    <br />
                                    2ª a 6ª
                                </span>
                            </div>
                            <a
                                href="mailto:contato@sambay.com.br"
                                className="span-email"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                contato@sambay.com.br
                            </a>
                            <div className="section-stickers">
                                <div className="section-wrap">
                                    <a
                                        href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fsambayexpress.com.br"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={GoogleSafe}
                                            alt="google-safe"
                                            className="img-google-safe"
                                        />
                                    </a>
                                    <img
                                        src={segurossl}
                                        alt="seguro-ssl"
                                        className="img-seguro-ssl"
                                    />
                                    <img
                                        src={letsencrypt}
                                        alt="lets-encrypt"
                                        className="img-lets-encrypt"
                                    />
                                    <span
                                        className={`
                                        tw-m-auto tw-flex tw-h-16 tw-appearance-none
                                        tw-flex-row tw-items-center tw-space-x-1
                                        tw-rounded tw-bg-white tw-px-2 tw-py-px
                                        tw-text-lg tw-font-semibold tw-text-primary-500
                                    `}
                                    >
                                        <MdOutlineLocalShipping
                                            color="#05a5bf"
                                            size={24}
                                        />
                                        <label>{`Entrega garantida`}</label>
                                    </span>
                                    <a
                                        href="https://www.reclameaqui.com.br/empresa/sambay-express/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={ReclameAquiPicture}
                                            width={240}
                                            height={75}
                                            alt="Icone-do-reclame-aqui"
                                        />
                                    </a>
                                    <a
                                        href="https://pequenoprincipe.org.br/doadores/apoie-o-pequeno-principe/outras-formas-de-doar/pequeno-principe-gols-pela-vida/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={Logopp}
                                            alt="logo-pequeno-principe"
                                            className="img-logo-pp"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a
                            href="https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="whatsapp_float">
                                <div className="SammyButton">
                                    <img
                                        src={IconButton}
                                        className="StyleIconButton"
                                    />
                                </div>
                            </div>
                        </a>
                    </div>
                </>
            )}

            {/* CÓDIGO DE RODAPÉ PARA TABLET */}
            {innerWidth >= 768 && innerWidth <= 1022 && (
                <>
                    <div className="section-footer-tablet">
                        <div className="footer-tablet-sambay-logo">
                            <img
                                src={sambayLogo}
                                alt="sambay-logo-tablet-footer"
                                className="sambay-logo-tablet-footer"
                            />
                            <div className="section-row-socials-tablet">
                                <a
                                    href="https://www.facebook.com/sambayexpress"
                                    target="blank"
                                >
                                    <img
                                        src={LogoFaceFooter}
                                        alt="facebook-logo-tablet-footer"
                                        className="facebook-logo-tablet-footer"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/sambayexpress/"
                                    target="blank"
                                >
                                    <img
                                        src={LogoInstaFooter}
                                        alt="instagram-logo-tablet-footer"
                                        className="instagram-logo-tablet-footer"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/sambay-express/"
                                    target="blank"
                                >
                                    <img
                                        src={LogoLinkedinFooter}
                                        alt="linkedin-logo-tablet-footer"
                                        className="linkedin-logo-tablet-footer"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="footer-tablet-infos">
                            <h2 className="title-footer-tablet-contact-infos">
                                Contato
                            </h2>
                            <span>
                                CNPJ n.° 40.559.278/0001-18
                                <br />
                                contato@sambay.com.br
                                <br />
                                SAC: 9090 98509-4049
                            </span>
                        </div>
                        <div className="footer-tablet-institutional-infos">
                            <h2 className="title-footer-tablet-institutional-infos">
                                Institucional
                            </h2>
                            <span
                                onClick={() => history.push(`/`)}
                                className="footer-tablet-instuticional-title-initial-page"
                            >
                                Página Inicial
                            </span>
                            <span
                                onClick={() => history.push(`/about-us`)}
                                className="footer-tablet-instuticional-title-who-we-are"
                            >
                                Quem Somos
                            </span>
                            <span
                                onClick={() => history.push(`/faq`)}
                                className="footer-tablet-instuticional-title-faq"
                            >
                                FAQ
                            </span>
                            <a
                                href={`${process.env.REACT_APP_STATIC_URL}/policy/termos.pdf`}
                                className="span-redirect"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Termos de uso
                            </a>
                        </div>
                        <div className="footer-tablet-section-imgs">
                            <div className="section-imgs">
                                <a
                                    href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fsambayexpress.com.br"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={GoogleSafe}
                                        alt="google-safe"
                                        className="footer-tablet-section-imgs-google-safe"
                                    />
                                </a>
                                <img
                                    src={segurossl}
                                    alt="seguro-ssl"
                                    className="footer-tablet-section-imgs-ssl"
                                />
                            </div>
                            <img
                                src={letsencrypt}
                                alt="lets-encrypt"
                                className="footer-tablet-section-imgs-lets-encrypt"
                            />
                            <span
                                className={`
                                        tw-flex tw-appearance-none tw-flex-row tw-items-center
                                        tw-space-x-1 tw-rounded tw-bg-white
                                        tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                        tw-text-primary-500
                                    `}
                            >
                                <MdOutlineLocalShipping
                                    color="#05a5bf"
                                    size={24}
                                />
                                <label>{`Entrega garantida`}</label>
                            </span>
                            <a
                                href="https://pequenoprincipe.org.br/doadores/apoie-o-pequeno-principe/outras-formas-de-doar/pequeno-principe-gols-pela-vida/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={Logopp}
                                    alt="logo-pequeno-principe"
                                    className="img-logo-pp"
                                />
                            </a>
                        </div>
                    </div>
                </>
            )}

            {/* CÓDIGO DE RODAPÉ PARA MOBILE */}
            {innerWidth < 768 && (
                <>
                    <div className="section-footer-mobile">
                        <div className="section-footer-mobile-logo-sambay">
                            <img
                                src={sambayLogo}
                                alt="logotipo-sambay-rodapé-mobile"
                                className="logo-sambay"
                            />
                        </div>
                        <div className="section-footer-mobile-sac">
                            <div className="section-footer-mobile-img-sac">
                                <img
                                    src={logoSac}
                                    alt="logo-sac"
                                    className="img-mobile-footer-sac"
                                />
                                <div className="section-mobile-footer-phone-number">
                                    <h2 className="title-sac-footer-mobile">
                                        SAC
                                    </h2>
                                    <span className="phone-number-footer-mobile-sac">
                                        9090 98509-4049
                                    </span>
                                </div>
                            </div>
                            <span className="support-number-footer-mobile-sac">
                                Atendimento das
                                <br />
                                <strong>08:30 até 17:00</strong>
                                <br />
                                2ª a 6ª
                            </span>
                        </div>
                        <div className="section-contact-sambay">
                            <a
                                href="mailto:contato@sambay.com.br"
                                className="contact-email-adress-footer-mobile-sac"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                contato@sambay.com.br
                            </a>
                        </div>
                        <div className="section-footer-mobile-socials">
                            <div className="section-container-footer-mobile-socials">
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.facebook.com/sambayexpress"
                                    >
                                        <img
                                            src={logoFacebook}
                                            className="icon-facebook-footer-mobile"
                                            alt="icon-facebook"
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/sambayexpress/"
                                    >
                                        <img
                                            src={logoInstagram}
                                            className="icon-instagram-footer-mobile"
                                            alt="icon-instagram"
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.linkedin.com/company/sambay-express/"
                                    >
                                        <img
                                            src={logolinkedin}
                                            className="icon-linkedin-footer-mobile"
                                            alt="icon-linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="section-client-support-footer-mobile">
                            <h2 className="title-section-client-support-footer-mobile">
                                Atendimento ao cliente
                            </h2>
                            <div className="section-links-client-support-footer-mobile">
                                <span
                                    onClick={() => history.push(`/faq`)}
                                    className="span-redirect"
                                >
                                    Central de Ajuda/FAQ
                                </span>
                                <a
                                    href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                                    className="span-redirect"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Como vender
                                </a>
                                <a
                                    href="https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Fale conosco
                                </a>
                                <span
                                    className="span-redirect"
                                    onClick={() => history.push(`/my-buys`)}
                                >
                                    Rastreie seu Pedido
                                </span>
                                <span
                                    className="span-redirect"
                                    onClick={() => history.push(`/commission-policy`)}
                                >
                                    Taxas e Comissões
                                </span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/warranty-policy`)
                                    }
                                >
                                    Política de Garantia
                                </span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/exchange-policy`)
                                    }
                                >
                                    Política de Trocas
                                </span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/return-policy`)
                                    }
                                >
                                    Política de Devoluções
                                </span>
                                <span
                                    className="span-redirect"
                                    onClick={() =>
                                        history.push(`/refund-policy`)
                                    }
                                >
                                    Política de Reembolso
                                </span>
                            </div>
                        </div>
                        <div className="section-client-support-footer-mobile">
                            <h2 className="title-section-client-support-footer-mobile">
                                Sobre a Sambay
                            </h2>
                            <div className="section-links-client-support-footer-mobile">
                                <span
                                    onClick={() => history.push(`/about-us`)}
                                    className="span-redirect"
                                >
                                    Sobre nós
                                </span>
                                <a
                                    href={`${process.env.REACT_APP_STATIC_URL}/policy/termos.pdf`}
                                    className="span-redirect"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Termos de uso
                                </a>
                                <span
                                    onClick={() =>
                                        history.push(`/privacy-policy`)
                                    }
                                    className="span-redirect"
                                >
                                    Politica de Privacidade
                                </span>
                                <span
                                    onClick={() =>
                                        history.push(`/social-projects`)
                                    }
                                    className="span-redirect"
                                >
                                    Projetos Sociais
                                </span>
                            </div>
                        </div>

                        <div className="section-payments-methods-footer-mobile">
                            <h2 className="title-section-payments-methods-footer-mobile">
                                Formas de pagamento
                            </h2>
                            <div className="section-container-payment-methods-footer-mobile">
                                <div className="container-center-width-payment-methods-footer-mobile">
                                    <img
                                        src={logoVisa}
                                        alt="logo-visa"
                                        className="logo-visa-footer-mobile"
                                    />
                                    <img
                                        src={logoMastercard}
                                        alt="logo-mastercard"
                                        className="logo-mastercard-footer-mobile"
                                    />
                                    <img
                                        src={logoElo}
                                        alt="logo-elo"
                                        className="logo-elo-footer-mobile"
                                    />
                                    <img
                                        src={logoPix}
                                        alt="logo-pix"
                                        className="logo-pix-footer-mobile"
                                    />
                                    <img
                                        src={logoBoleto}
                                        alt="logo-boleto"
                                        className="logo-boleto-footer-mobile"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="section-payments-methods-footer-mobile">
                            <h2 className="title-section-payments-methods-footer-mobile">
                                Certificados
                            </h2>
                            <div className="section-container-stickers-footer-mobile">
                                <div className="container-center-width-payment-methods-footer-mobile tw-space-y-2">
                                    <img
                                        src={GoogleSafe}
                                        alt="logo-google-safe"
                                        className="logo-google-safe"
                                    />
                                    <img
                                        src={segurossl}
                                        alt="logo-seguro-ssl"
                                        className="logo-seguro-ssl"
                                    />
                                    <img
                                        src={letsencrypt}
                                        alt="logo-lets-encrypt"
                                        className="logo-lets-encrypt"
                                    />
                                    <span
                                        className={`
                                        tw-flex tw-appearance-none tw-flex-row tw-items-center
                                        tw-space-x-1 tw-rounded tw-bg-white
                                        tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                        tw-text-primary-500
                                    `}
                                    >
                                        <MdOutlineLocalShipping
                                            color="#05a5bf"
                                            size={24}
                                        />
                                        <label>{`Entrega garantida`}</label>
                                    </span>

                                    <a
                                        href="https://www.reclameaqui.com.br/empresa/sambay-express/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={ReclameAquiPicture}
                                            width={180}
                                            height={60}
                                            alt="Icone-do-reclame-aqui"
                                        />
                                    </a>
                                    <img
                                        src={Logopp}
                                        alt="logo-pp"
                                        className="logo-pp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Container>
    )
}

export default Footer
