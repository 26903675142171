import React, { useEffect } from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from './Routes'
import store, { persistor } from './store'
import { AppProvider } from './provider'
import { setAccessToken } from './AccessToken'
import ReactGa from 'react-ga4'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import 'react-toastify/dist/ReactToastify.css'
import TagManager from 'react-gtm-module'
// import NoticeScreen from './components/NoticeScreen/NoticeScreen'

TagManager.initialize({
    gtmId: 'GTM-NJT8BHG8',
})
const helmetContext = {}

function App() {
    useEffect(() => {
        process.env.REACT_APP_GA_TRACKING_ID &&
            ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_ID)
        fetch(`${process.env.REACT_APP_API_URL}/refresh_token`, {
            method: 'POST',
            credentials: 'include',
        }).then(async (result) => {
            const { accessToken } = await result.json()

            setAccessToken(accessToken)
        })
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={undefined} persistor={persistor}>
                <AppProvider>
                    <HelmetProvider context={helmetContext}>
                        {/* <NoticeScreen /> */}
                        <Router>
                            <Routes />
                        </Router>
                    </HelmetProvider>
                </AppProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
