import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import { Link } from 'react-router-dom'
import pdvs from './zeq_pdvs.json'

const ZeqPointsOfSale: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Pontos de Venda do Zequinha</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Pontos de Venda do Zequinha</h1>
                        <p>
                            Lista em constante atualização!
                        </p>
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Nome do Ponto</th>
                                    <th>Endereço</th>
                                    <th>Número</th>
                                    <th>Bairro</th>
                                    <th>Cidade</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pdvs.map((pdv, index) => {
                                    return <tr key={index}>
                                        <td>{pdv.name}</td>
                                        <td>{pdv.address}</td>
                                        <td>{pdv.number}</td>
                                        <td>{pdv.district}</td>
                                        <td>{pdv.city}</td>
                                        <td>{pdv.uf}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default ZeqPointsOfSale
